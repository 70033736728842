<script setup lang="ts">
const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
</script>

<template>
  <div class="footer-nav">
    <div class="container">
      <a href="#" @click.prevent="scrollTop">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.9934 13.165L11.6393 13.1634C10.2348 13.1513 8.98174 13.0666 8.18763 12.9206C8.17331 12.9206 7.38572 12.7613 7.12355 12.6599C6.74463 12.5006 6.42298 12.2126 6.2192 11.8515C6.0738 11.5624 6 11.2592 6 10.9417C6.01157 10.6922 6.13714 10.2806 6.24242 9.98692L6.33596 9.74283C6.97815 8.12612 9.07656 5.03561 10.3598 3.85199L10.4495 3.76473L10.8698 3.37527C11.1915 3.12982 11.5847 3 12.0077 3C12.3866 3 12.7656 3.11564 13.0718 3.3469C13.171 3.41614 13.2983 3.52766 13.4058 3.62693L13.8026 4.006C15.1243 5.31046 16.9782 8.13485 17.5924 9.59883C17.6057 9.59883 17.9857 10.5086 18 10.9417L18 10.9995C18 11.6639 17.6211 12.2846 17.0086 12.6021C16.8412 12.6888 16.5132 12.772 16.2252 12.8352L15.6813 12.949C14.8067 13.0788 13.465 13.165 11.9934 13.165ZM13.5183 19.4967C13.5183 20.3269 12.8387 21 12.0004 21C11.1622 21 10.4825 20.3269 10.4825 19.4967L10.8097 15.7975C10.8097 15.1463 11.3417 14.6183 12.0004 14.6183C12.6581 14.6183 13.1912 15.1463 13.1912 15.7975L13.5183 19.4967Z"
            fill="#269FF3"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "Navigation";
</style>
